.copyright {
  background-color: var(--gold);
  padding: 20px !important;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.footer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.06);
  padding: 60px 0px 120px 0px;
  position: relative;
  /* border-top-left-radius: 35px;
  border-top-right-radius: 35px; */
}
.footerLayout {
  display: grid;
  color: var(--white);
  width: 90vw;
  max-width: 1200px;
  margin: auto;
}
.company {
  grid-area: company;
}
.community {
  grid-area: community;
}
.links {
  grid-area: links;
}

@media (max-width: 576px) {
  .footerLayout {
    grid-template-areas:
      "company"
      "community"
      "links";
  }
  .community {
    margin-top: 20px;
  }
  .links {
    margin-top: 20px;
  }
}

@media (min-width: 576px) {
  .footerLayout {
    grid-template-areas:
      "company company"
      "community links";
  }
  .community {
    margin-top: 20px;
  }
  .links {
    margin-top: 20px;
  }
}

@media (min-width: 992px) {
  .footerLayout {
    grid-template-areas: "company company community links";
  }
}
.desc {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 36px;
}
.community,
.links {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.community div,
.links div {
  padding: 9px 0px;
}
.social {
  display: flex;
}
.social div {
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 15px;
  margin-right: 12px;
  margin-top: 20px;
}
.social div img {
  width: 24px;
  height: auto;
}
.link {
  text-decoration: none;
}
.link:hover {
  color: var(--gold);
}
