.container {
  max-width: 1200px;
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90vw;
  min-height: 90vh;
  flex-wrap: wrap;
}

h3 {
  color: #fff;
}

a:link,
a:active,
a:visited {
  color: rgba(255, 255, 255, 1);
}

a:hover {
  color: rgba(255, 255, 255, 0.6);
}

.box-title {
  width: 100%;
  position: absolute;
  top: 85%;
  text-align: left;
}

.box-callout {
  width: 100%;
  margin: 30px auto;
  padding: 0;
  max-width: 350px;
  cursor: pointer;
}

.box-callout figure {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.box-callout figure img {
  width: 100%;
}

.box-title h3 {
  margin: 0 auto;
  font-size: 1.4em !important;
  color: #fff !important;
  margin-left: 15px;
  text-align: left !important;
  text-transform: uppercase;
}

.box-callout p {
  font-size: 1em !important;
  color: #444 !important;
  text-align: left !important;
}

.box-callout figcaption p {
  color: #fff !important;
}

.box-callout figcaption ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.box-callout h4 {
  margin: 10px 0 0;
  padding: 0;
}

.box-callout figcaption {
  padding: 10%;
  color: transparent;
  background-color: transparent;
  position: absolute;
  z-index: 996;
  bottom: 0;
  left: 0;
  height: 0;
  margin: 0 0 !important;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1.6em;
  justify-content: center;
  overflow: hidden;
  visibility: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.box-callout figure:hover figcaption {
  visibility: visible;
  color: #fff;
  background: var(--gold);
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 2;
}

.box-callout figure img {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.box-callout figure:hover img {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

@media only screen and (max-width: 765px) {
  .container {
    flex-direction: column;
  }
}
.subtitle {
  font-weight: 500;
  font-size: 16px !important;
  line-height: 28px !important;
}
.dialog {
  background-color: var(--dark-background);
  color: var(--gold);
}
.popupText {
  color: var(--white);
}
