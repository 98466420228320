.Testimonial {
  width: 100vw;
  padding: 70px calc((100vw - 1000px) / 2);
  background-color: rgba(255, 255, 255, 0.06);
}
.display {
  display: flex;
  align-items: center;
  width: 90vw;
  max-width: 1000px;
  margin: auto;
}
.display div {
  flex: 1;
}
.display div:nth-of-type(2) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.display img {
  max-width: 460px;
  height: auto;
}
.section {
  color: var(--primary-green);
  text-transform: uppercase;
  margin-bottom: 14px;
}
.show {
  font-weight: 700;
  font-size: clamp(30px, 3.5vw, 48px);
  line-height: 125%;
  letter-spacing: -0.02em;
  color: var(--white);
}
.desc {
  margin-top: clamp(15px, 1.4vw, 24px);
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  color: #b1b5c4;
}

@media (max-width: 800px) {
  .display {
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .display img {
    max-width: 90vw;
    height: auto;
  }
}

.apply {
  margin-top: clamp(30px, 3vw, 45px);
  margin-bottom: clamp(30px, 3vw, 45px);
  outline: none;
  border: none;
  padding: clamp(15px, 1.5vw, 21px) clamp(30px, 3vw, 42px);
  border-radius: 50px;
  font-size: clamp(13px, 1.5vw, 21px);
  border: 2px solid #b09869;
  transition: all 0.2s ease-in-out;
  background: linear-gradient(
    92.97deg,
    #3d2b0a 3.8%,
    #fcebbf 34.39%,
    #eec96c 57.07%,
    #fed87f 99.59%
  );
  color: #060a15 !important;
  font-weight: 500;
  cursor: pointer;
}

.apply:hover {
  box-shadow: 0px 8px 15px -5px #b09869;
  background: linear-gradient(
    100deg,
    #3d2b0a 3.8%,
    #fcebbf 34.39%,
    #eec96c 57.07%,
    #fed87f 99.59%
  );
}
