.utility {
  width: 90vw;
  max-width: 1200px;
  margin: 80px auto;
}
.heading {
  font-weight: 700;
  font-size: clamp(28px, 3.3vw, 46px);
  line-height: 125%;
  letter-spacing: -0.02em;
  color: var(--white);
}
.accordion {
  background: transparent !important;
  color: var(--white) !important;
  padding-bottom: 10px;
  border-bottom: 1px solid #353945 !important;
}
.utility h4 {
  color: #b1b5c4;
  font-weight: 500;
  font-size: clamp(18px, 1.6vw, 24px);
  line-height: clamp(28px, 3vw, 40px);
}
