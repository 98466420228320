.videoButton {
  background: #303034;
  border: 1px solid #eeeeee;
  box-shadow: 0px 4px 16px rgba(103, 103, 103, 0.04);
  border-radius: 16px;
  height: 226px;
  width: 100%;
  display: flex;
  padding: 28px;
}
.videoImage {
  width: 192px;
  height: 192px;
}
.videoImageContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: fit-content;
}
.heroText {
  flex: 1;
}
.header {
  width: 586px;
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  display: flex;
  align-items: center;
  color: #f2f2f2;
}
.button {
  padding: 11px 12px;
  gap: 8px;
  width: 149px;
  height: 42px;
  color: #fff;
  background: radial-gradient(
    48.33% 48.33% at 50% 24.8%,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  ),
  radial-gradient(
    61.85% 61.85% at 50% 8.33%,
    rgba(251, 241, 202, 0.35) 0%,
    rgba(255, 226, 73, 0.5) 100%
  ),
  #9b8538;
  /* box-shadow: 0px 104px 200px rgba(0, 0, 0, 0.25),
    inset 72px 157px 200px #000000,
    inset 0px -137px 200px rgba(255, 255, 255, 0.02); */
  border-radius: 4px;
  outline: none;
  border: none;
  margin-top: 50px;
  cursor: pointer;
  font-size: 18px;
}
