.container {
  width: 100vw;
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1d1e1f;
  opacity: 0.8;
  background-image: linear-gradient(#222324 2px, transparent 2px),
    linear-gradient(to right, #222324 2px, #1d1e1f 2px);
  background-size: 50px 50px;
}
.formContainer {
  width: 420px;
  overflow: hidden;
  background: #282828;
  box-shadow: -2px 4px 16px rgba(255, 255, 255, 0.01);
  border-radius: 2px;
  padding: 32px 24px 40px 24px;
}
.formContainer > h1 {
  color: #f2f2f2;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  margin-bottom: 40px;
}

.buttonContainer {
  justify-content: center;
  margin-top: 40px;
  position: relative;
}
.forgotPassword {
  color: #f2f2f2;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin-top: 16px;
}
