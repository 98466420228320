@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;500;600;700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Exo 2", sans-serif !important;
}
body {
  background: #040914;
  overflow-x: hidden;
  --dark-background: #040914;
  --light-background: #243e57;
  --gold: #c79d54;
  --white: #fff;
  --black: #000;
  --btn-color: #212a4d;
  --primary-green: #45b36b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.vertical-timeline::before {
  height: 130% !important;
  background: linear-gradient(
    180.14deg,
    rgba(199, 157, 84, 0) 4.41%,
    #c79d54 53.15%,
    rgba(199, 157, 84, 0) 97.42%
  ) !important;
  transform: translate(0, -17%) !important;
}
.vertical-timeline-element-date {
  color: var(--gold) !important;
  font-weight: 600 !important;
  font-size: clamp(28px, 3vw, 40px) !important;
  line-height: clamp(42px, 5vw, 70px) !important;
}

.vertical-timeline-element-content p {
  margin-top: 0px !important;
}
.vertical-timeline-element-icon {
  margin-left: -20px;
}
@media (max-width: 1200px) {
  .vertical-timeline-element-icon {
    margin-left: 0px;
  }
}
.slider-left {
  display: none !important;
}
.slider-right {
  display: none !important;
}
