.header {
  position: relative;
  width: clamp(300px, 90%, 1440px);
  margin: 60px auto;
}
@media (max-width:600px) {
  .header {
    margin: 35px auto;
  }
}
.headerText {
  color: #f2f2f2;
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
}
.breadcrumb {
  color: #f2f2f2;
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 8px;
}
.breadcrumb > ol > li {
  color: #f2f2f2;
}

.links {
  text-decoration: none;
}
