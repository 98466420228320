.container {
  width: 100vw;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-bottom: 60px;
}
.childContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.formContainer {
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 4px 16px rgba(103, 103, 103, 0.04);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  display: flex;
  overflow: hidden;
  width: clamp(300px, 90%, 1440px);
}
.formContainer form {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.formContainer form label {
  color: #9f9f9f !important;
}

.inputs {
  flex: 1;
  margin: 16px 24px ;
  margin-bottom: 0px;
  overflow: scroll;
  ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  padding-bottom: 10px;
}
.inputs::-webkit-scrollbar {
  display: none;
}
.buttonContainer {
  background-color: #eeeeee;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  height: 80px;
}

.buttonContainer button {
  background: linear-gradient(180deg, #3b3b3b 0%, #1d1e1f 100%);
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
}
.changePassword {
  color: #3b3b3b !important;
  font-size: 14px;
  line-height: 17px;
  text-decoration: underline;
  margin: auto 0px;
}
