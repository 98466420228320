.container {
  width: 100vw;
  background: #1d1e1f;
  min-height: calc(100vh - 90px);
  display: flex;
  align-items: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
}
.childContainer {
  width: 50%;
  display: flex;
  justify-content: center;
  /* height: calc(100vh - 90px); */
  padding: calc(13vh - 45px) 0px;
}

.formContainer {
  background-color: #282828;
  border-radius: 12px;
  position: relative;
  display: flex;
  overflow: hidden;
  width: 84%;
  max-width: 90vw;
}
.formContainer form {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.inputs {
  flex: 1;
  margin: 32px 24px;
  margin-bottom: 0px;
  overflow: scroll;
  ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  padding-bottom: 20px;
  /* padding-bottom: 80px; */
}
.inputs::-webkit-scrollbar {
  display: none;
}
.buttonContainer {
  background-color: #333333;
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  height: 80px;
}
.error {
  color: red;
  margin-bottom: 5px;
}

.darksuccess {
  width: 100%;
  max-width: 90vw;
  display: grid;
  place-items: center;
}
.darksuccess img {
  margin-bottom: 28px;
}
.darksuccess > p {
  color: #000;
}
.darksuccess > div {
  color: #000;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  margin-bottom: 12px;
  text-align: center;
}

.success {
  width: 100%;
  max-width: 90vw;
  display: grid;
  place-items: center;
}
.success img {
  margin-bottom: 28px;
}
.success > div {
  color: #f2f2f2;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  margin-bottom: 12px;
  text-align: center;
}
.heroText {
  color: #f2f2f2;
  font-style: normal;
  width: 84%;
  max-width: 90vw;
}
.heroText > h1 {
  font-weight: 500;
  font-size: clamp(34px, 3.5vw, 52px);
  line-height: 125%;
  margin-bottom: 28px;
}
.heroText > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #a6a6a6;
}

.containr {
  border: 1px dashed #3b3b3b;
  border-radius: 8px;
  padding: 8px;
  margin-top: 12px;
}

.dragnDropText {
  color: rgb(102, 102, 102);
  margin-bottom: 12px;
}

.dropzone {
  width: 100%;
  height: 150px;
  border: 2px dashed #a6a6a6;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  margin-top: 6px;
  padding: 20px;
}
.label {
  color: #f2f2f2;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.selectFiles {
  background: transparent;
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding: 4px 8px 4px 0px;
  border: none;
  outline: none;
  color: #fff9e6;
  opacity: 1;
  cursor: pointer;
  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .childContainer {
    width: 100vw;
    height: fit-content !important;
    padding: 32px 0px;
  }
}
