.cards {
  padding: 20px;
}

.referralCode {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #1c1c1c;
  margin-top: 15px;
  margin-bottom: 4px;
}
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #878787;
}

.copyButton {
  margin-left: 10px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
}
.copyButton:hover {
  opacity: 0.7;
}
