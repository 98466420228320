.disclaimer {
  /* margin: 100px calc((100vw - 1200px) / 2); */
  color: var(--white);
  width: 90vw;
  max-width: 1200px;
  margin: clamp(40px, 7vw, 100px) auto;
}

.disclaimer h3 {
  font-weight: 600;
  font-size: clamp(42px, 3vw, 60px);
  line-height: clamp(50px, 3.5vw, 75px);
  color: var(--gold);
  text-transform: uppercase;
  margin-bottom: 40px;
}

.disclaimer h4 {
  margin: 46px 0 10px 0px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: clamp(24px, 2.2vw, 32px);
  line-height: clamp(30px, 3vw, 42px);
}
.disclaimer p {
  font-weight: 500;
  font-size: clamp(18px, 1.6vw, 24px);
  line-height: clamp(28px, 3vw, 40px);
}
