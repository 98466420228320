.timeline {
  color: var(--white);
  width: 90vw;
  max-width: 1200px;
  margin: auto;
  min-height: 90vh;
  overflow-y: hidden;
  margin-bottom: 80px;
}
.timeline > h3 {
  text-align: center;
  font-weight: 600;
  font-size: clamp(42px, 3vw, 60px);
  line-height: clamp(50px, 3.5vw, 75px);
  color: var(--gold);
}
.timelineComp {
  margin-top: 140px;
}
.ListItem {
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
}
.text {
  padding: 80px 0px;
  color: #b1b5c4;
  font-weight: 500;
  font-size: clamp(18px, 1.6vw, 24px);
  line-height: clamp(28px, 3vw, 40px);
}
