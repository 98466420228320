.buttonDisabled {
  background-image: radial-gradient(
      48.33% 48.33% at 50% 24.8%,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      61.85% 61.85% at 50% 8.33%,
      rgba(251, 241, 202, 0.35) 0%,
      rgba(255, 226, 73, 0.5) 100%
    ),
    #f9cd31;
  opacity: 0.5;
  box-shadow:
    0px 104px 200px rgba(0, 0, 0, 0.25),
    inset 72px 157px 200px #000000,
    inset 0px -137px 200px rgba(255, 255, 255, 0.02);
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  padding: 14px;
  max-width: 80vw;
  border: none;
  outline: none;
  width: 150px;
  color: #fff;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}
.buttonEnabled {
  background:radial-gradient(
    48.33% 48.33% at 50% 24.8%,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  ),
  radial-gradient(
    61.85% 61.85% at 50% 8.33%,
    rgba(251, 241, 202, 0.35) 0%,
    rgba(255, 226, 73, 0.5) 100%
  ),
  #846a11;
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  padding: 14px;
  border: none;
  outline: none;
  width: 150px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}
.IconButton {
  display: flex;
  margin: 0 10px;

  align-items: center;
  justify-content: center;
  background-color: rgba(244, 244, 244, 0.5);
  border-radius: 4px;
  border: 2px solid #6f767e;
  outline: none;
  padding: 5px 12px;
  transition: all 0.2s ease-in-out;
}
.IconButton:hover {
  background-color: rgba(244, 244, 244, 1);
}
