.container {
  width: 100vw;
  min-height: 100vh;
  background-color: #ffffff;
}
.content::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 360px;
  background-color: #1d1e1f;
  background-image: linear-gradient(#222324 2px, transparent 2px),
    linear-gradient(to right, #222324 2px, #1d1e1f 2px);
  background-size: 50px 50px;
  opacity: 0.98;
}
.mainContent {
  position: relative;
  width: clamp(300px, 90%, 1440px);
  margin: 0 auto;
  padding: 18px 0px;
  gap: 24px;
  display: flex;
  flex-direction: row;
}
.mainContent_custom {
  position: relative;
  width: clamp(300px, 90%, 1440px);
  margin: 0 auto;
  padding: 18px 0px;
  gap: 24px;
  display: flex;
  flex-direction: row;
}
.mainContent_marketplace {
  width: 90vw;
  max-width: 1300px;
  margin: 3rem auto 5rem;
}
@media (max-width: 1080px) {
  .mainContent_marketplace {
    width: 90vw;
    margin: 2rem auto 2rem;
  }
}
@media (max-width:600px) {
  .mainContent_custom{
    flex-direction: column;
    gap:"2rem"
  }
}
.grid {
  position: relative;
  width: clamp(300px, 90%, 1440px);
  margin: 60px auto 18px auto;
  height: 162px;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, 1fr);
}

.grid > div {
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 4px 16px rgba(103, 103, 103, 0.04);
  border-radius: 4px;
}
