.header {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #1c1c1c;
}
.list {
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 4px 16px rgba(103, 103, 103, 0.04);
  border-radius: 2px;
  height: 480px;
  padding: 16px;
}
.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}
.listItem div {
  flex: 1;
}
.listItem:nth-child(1) {
  color: #9f9f9f;
  padding-bottom: 16px !important;
  padding: 0;
}
.listItem {
  padding: 12px 0px;
  margin: 0;
  border-bottom: 1px solid #eeeeee;
}
.image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
  object-fit: contain;
}

.listItem img {
  width: 100%;
  height: auto;
}

.listItem > div:nth-child(1) {
  display: flex;
  flex-direction: row;
}
.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 42px;
}
.uploadButton {
  z-index: 2;
  right: 16px;
  outline: none;
  border: none;
  background: linear-gradient(180deg, #3b3b3b 0%, #1d1e1f 100%);
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
  padding: 12px 20px;
  color: #f2f2f2;
}
.cancel_ {
  z-index: 2;
  right: 16px;
  outline: none;
  border: 2px solid #1d1e1f;
  background: #ffffff;
  /* background: linear-gradient(180deg, #3b3b3b 0%, #1d1e1f 100%); */
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
  padding: 12px 20px;
  color: #1d1e1f;
  width: 100px;
  height: 44px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload_ {
  width: 100px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  right: 16px;
  outline: none;
  border: 2px solid #1d1e1f;
  background: linear-gradient(180deg, #3b3b3b 0%, #1d1e1f 100%);
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
  padding: 12px 20px;
  color: #f2f2f2;
  margin-right: 16px;
  cursor: pointer;
}