.earlyAccess {
  margin: 80px auto;
}
.heading {
  font-weight: 700;
  font-size: clamp(28px, 3.3vw, 46px);
  line-height: 125%;
  letter-spacing: -0.02em;
  color: var(--white);
}
.apply {
  outline: none;
  border: none;
  padding: clamp(15px, 1.5vw, 21px) clamp(30px, 3vw, 42px);
  border-radius: 50px;
  font-size: clamp(13px, 1.5vw, 21px);
  border: 2px solid #b09869;
  transition: all 0.2s ease;
  background: linear-gradient(
    92.97deg,
    #3d2b0a 3.8%,
    #fcebbf 34.39%,
    #eec96c 57.07%,
    #fed87f 99.59%
  );
  color: #060a15 !important;
  font-weight: 500;
  cursor: pointer;
}

.apply:hover {
  box-shadow: 0px 8px 15px -5px #b09869;
  background: linear-gradient(
    100deg,
    #3d2b0a 3.8%,
    #fcebbf 34.39%,
    #eec96c 57.07%,
    #fed87f 99.59%
  );
}
.header {
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  width: 90vw;
  max-width: 1200px;
  margin: auto;
}
.controls {
  display: flex;
  gap: 20px;
}
.controls div {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--white);
  cursor: pointer;
}
.controls div img {
  pointer-events: none;
}
