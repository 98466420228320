.loader {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.loader div {
  display: inline-block;
  position: absolute;
  left: 0px;
  width: 4px;
  background: #f2f2f2e5;
  animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loader div:nth-child(1) {
  left: 0px;
  animation-delay: -0.24s;
}
.loader div:nth-child(2) {
  left: 8px;
  animation-delay: -0.12s;
}
.loader div:nth-child(3) {
  left: 16px;
  animation-delay: 0;
}
@keyframes loader {
  0% {
    top: 0px;
    height: 20px;
  }
  50%,
  100% {
    top: 8px;
    height: 12px;
  }
}
