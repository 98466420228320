.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  max-width: 1300px;
  margin: auto;
  width: 90vw;
}
.nav ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  color: var(--white);
}
.nav ul li {
  padding: 8px;
  margin-left: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
}
.nav ul li:last-child {
  outline: none;
  border: none;
  padding: 12px 20px !important;
  border-radius: 50px;
  font-size: 18px;
  line-height: 20px;
  border: 2px solid #b09869;
  transition: all 0.2s ease-in-out;
  background: linear-gradient(
    92.97deg,
    #3d2b0a 3.8%,
    #fcebbf 34.39%,
    #eec96c 57.07%,
    #fed87f 99.59%
  );
  text-decoration: none;
  color: #060a15 !important;
  font-weight: 500;
  cursor: pointer;
}

.nav ul li:last-child a {
  text-decoration: none;
  color: inherit;
}

.nav ul li:last-child:hover {
  box-shadow: 0px 8px 15px -5px #b09869;
  background: linear-gradient(
    100deg,
    #3d2b0a 3.8%,
    #fcebbf 34.39%,
    #eec96c 57.07%,
    #fed87f 99.59%
  );
}

.link {
  text-decoration: none;
}

.link:hover {
  color: var(--gold);
}

.menuMobile {
  display: none;
}

.burger {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.burger span {
  width: 30px;
  height: 3px;
  border-radius: 3px;
  background-color: #f2f2f2;
}
.burger > span:nth-child(2) {
  margin-top: 8px;
  width: 25px;
}
@media (max-width: 1180px) {
  .menu {
    display: none;
  }
  .menuMobile {
    display: block;
  }
}
.drawer {
  width: 256px;
  padding: 40px 0px;
  background-color: var(--dark-background);
  height: 100vh;
}
.drawer ul li {
  padding: 12px 24px;
  color: #fff;
  list-style: none;
  transition: all 0.2s ease;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
}
.drawer ul li:last-child {
  outline: none;
  border: none;
  padding: 12px 20px !important;
  border-radius: 50px;
  font-size: 18px;
  line-height: 20px;
  border: 2px solid #b09869;
  transition: all 0.2s ease-in-out;
  background: linear-gradient(
    92.97deg,
    #3d2b0a 3.8%,
    #fcebbf 34.39%,
    #eec96c 57.07%,
    #fed87f 99.59%
  );
  text-decoration: none;
  color: #060a15 !important;
  font-weight: 500;
  cursor: pointer;
  width: fit-content;
  margin-left: 20px;
  margin-top: 10px;
}
.drawer ul li:last-child a {
  text-decoration: none;
  color: inherit;
}

.drawer ul li:last-child:hover {
  box-shadow: 0px 8px 15px -5px #b09869;
  background: linear-gradient(
    100deg,
    #3d2b0a 3.8%,
    #fcebbf 34.39%,
    #eec96c 57.07%,
    #fed87f 99.59%
  );
}
