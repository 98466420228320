.navbar {
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #4e4e4e;
  background-color: #1d1e1f;
  position: relative;
  z-index: 2;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 5vw;
}
.navContainer {
  display: flex;
  width: clamp(300px, 100%, 1440px);
  justify-content: space-between;
}
.logo {
  height: 44px;
  width: auto;
}

.userIcon {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background-color: #736c6c;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #1d1e1f;
  border: 2px solid #1d1e1f;
}

.profileDetails {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  cursor: pointer;
  color: #f2f2f2;
  text-decoration: none;
}
.link {
  text-decoration: none;
}

.link:hover {
  color: var(--gold);
}

.menuMobile {
  display: none;
}

.burger {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.burger span {
  width: 30px;
  height: 3px;
  border-radius: 3px;
  background-color: #f2f2f2;
}
.burger > span:nth-child(2) {
  margin-top: 8px;
  width: 25px;
}
@media (max-width: 1180px) {
  .menu {
    display: none;
  }
  .menuMobile {
    display: block;
  }
}
.drawer {
  width: 256px;
  padding: 40px 0px;
  background-color: var(--dark-background);
  height: 100vh;
}
.drawer ul li {
  padding: 12px 24px;
  color: #fff;
  list-style: none;
  transition: all 0.2s ease;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
}
.drawer ul li:last-child {
  outline: none;
  border: none;
  padding: 12px 20px !important;
  border-radius: 50px;
  font-size: 18px;
  line-height: 20px;
  border: 2px solid #b09869;
  transition: all 0.2s ease-in-out;
  background: linear-gradient(
    92.97deg,
    #3d2b0a 3.8%,
    #fcebbf 34.39%,
    #eec96c 57.07%,
    #fed87f 99.59%
  );
  text-decoration: none;
  color: #060a15 !important;
  font-weight: 500;
  cursor: pointer;
  width: fit-content;
  margin-left: 20px;
  margin-top: 10px;
}
.drawer ul li:last-child a {
  text-decoration: none;
  color: inherit;
}

.drawer ul li:last-child:hover {
  box-shadow: 0px 8px 15px -5px #b09869;
  background: linear-gradient(
    100deg,
    #3d2b0a 3.8%,
    #fcebbf 34.39%,
    #eec96c 57.07%,
    #fed87f 99.59%
  );
}
