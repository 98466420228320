.header {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #1c1c1c;
}

.imageComp {
  border-radius: 2px;
}
.image {
  position: relative;
}
.image > button {
  position: absolute;
  right: 0;
  margin: 8px;
  width: 32px;
  height: 32px;
  background: #ffffff;
  box-shadow: -1px 2px 16px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
  outline: none;
  border: none;
}
button:disabled {
  background: #1d1e1fbd;
  color: rgb(255 255 255 / 60%);
  cursor: not-allowed;
}
.image > button:hover {
  background: #f5f5f5;
  cursor: pointer;
}

.list {
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 4px 16px rgba(103, 103, 103, 0.04);
  border-radius: 2px;
  /* height: 480px; */
  padding: 16px;
  position: relative;
  overflow: auto;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 42px;
}
.uploadButton {
  z-index: 2;
  right: 16px;
  outline: none;
  border: none;
  background: linear-gradient(180deg, #3b3b3b 0%, #1d1e1f 100%);
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
  padding: 12px 20px;
  color: #f2f2f2;
  cursor: pointer;
}
.noImages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}
.noImages > img {
  width: clamp(200px, 50%, 300px);
  height: auto;
}
.noImages > h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #1c1c1c;
  margin-top: 16px;
}
.cancel {
  z-index: 2;
  right: 16px;
  outline: none;
  border: 2px solid #1d1e1f;
  background: #ffffff;
  /* background: linear-gradient(180deg, #3b3b3b 0%, #1d1e1f 100%); */
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
  padding: 12px 20px;
  color: #1d1e1f;
  width: 100px;
  height: 44px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload {
  width: 100px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  right: 16px;
  outline: none;
  border: 2px solid #1d1e1f;
  background: linear-gradient(180deg, #3b3b3b 0%, #1d1e1f 100%);
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
  padding: 12px 20px;
  color: #f2f2f2;
  margin-right: 16px;
  cursor: pointer;
}
