.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.custom_grid {
  position: relative;
  width: clamp(300px, 90%, 1440px);
  margin: 60px auto 18px auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.apexcharts-canvas{
  width: 400px !important;
}
@media (max-width:600px) {
  .apexcharts-canvas{
    width: auto !important;
  }
}
.mySwiper .swiper-pagination-bullet{
  background: #fff !important;
  opacity: 1 !important;
}
.mySwiper .swiper-pagination-bullet-active{
  background: #eec96c !important;
  opacity: 1 !important;
}

.main-image {
  grid-area: image;
  background-image: url("https://source.unsplash.com/EwKXn5CapA4");
  width: 100%;
  height: 100%;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
}

.sidebar {
  grid-area: sidebar;
  background-color: hsl(265, 100%, 86%);
  border-left: 1px solid hsl(265, 100%, 56%);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.sidebar-item {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: hsl(265, 100%, 86%);
  padding: 1rem;
  position: relative;
  transition: background-color 150ms;
}

.sidebar-item:hover,
.sidebar-item:focus {
  background-color: hsl(265, 100%, 76%);
}

.sidebar-item.active {
  background-color: hsl(265, 100%, 70%);
}

.sidebar-item::after {
  content: "";
  position: absolute;
  width: 80%;
  left: 10%;
  bottom: 0;
  height: 1px;
  background-color: hsl(265, 100%, 46%);
}

.sidebar-item:last-child::after {
  display: none;
}

.slider-container {
  grid-area: slider;
  margin-top: 2rem;
  padding: 2rem;
}

.slider {
  width: 100%;
  cursor: pointer;
}
.css-13cymwt-control{
  border-color: none;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
  border: 1px solid #3b3b3b !important;
  border-radius: 8px !important;
  padding: 4px;
  
  margin:6px 0 !important;
}
.css-19bb58m{
margin: 0 !important;
}
.css-t3ipsp-control{
  border-color: none;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
  border: 1px solid #3b3b3b !important;
  border-radius: 8px !important;
  color: #fff !important;
  padding: 4px;
  margin:6px 0 !important;
}