.hero {
  min-height: 540px;
  max-height: 660px;
  display: flex;
  align-items: center;
  width: 90vw;
  max-width: 1200px;
  margin: auto;
  position: relative;
}
.text {
  flex: 1;
}
.bannner {
  flex: 1;
}
.banner img {
  max-width: 660px;
  height: auto;
}
.banner::after {
  content: "";
  display: block;
  width: 290px;
  height: 290px;
  position: absolute;
  background: linear-gradient(119.99deg, #ffbee1 21.9%, #ed70b4 125.17%);
  border-radius: 50%;
  z-index: -1;
  transform: translate(80%, -100%) rotate(177.74deg);
  animation: breathe-reverse 4s cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
}
.text h3 {
  color: var(--white);
  font-weight: 500;
  font-size: clamp(34px, 3.5vw, 48px);
  line-height: 125%;
}
.text p {
  color: var(--white);
  font-size: clamp(14px, 1.5vw, 20px);
  font-weight: 300;
  line-height: 30px;
  margin-top: clamp(21px, 2.25vw, 30px);
}
.text > div {
  margin-top: clamp(40px, 4vw, 60px);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.text > div > a > button {
  outline: none;
  border: none;
  padding: clamp(15px, 1.5vw, 21px) clamp(30px, 3vw, 42px);
  margin-right: 20px;
  border-radius: 50px;
  font-size: clamp(13px, 1.5vw, 31px);
  border: 2px solid transparent;
  transition: all 0.2s ease;
}

.text > div > a > button:hover {
  background: linear-gradient(
    100deg,
    #3d2b0a 3.8%,
    #fcebbf 34.39%,
    #eec96c 57.07%,
    #fed87f 99.59%
  );
  box-shadow: 0px 8px 15px -5px #b09869;
}
.text > div > a > button:first-child {
  background: linear-gradient(
    92.97deg,
    #3d2b0a 3.8%,
    #fcebbf 34.39%,
    #eec96c 57.07%,
    #fed87f 99.59%
  );
  color: #060a15 !important;
  font-weight: 500;
  cursor: pointer;
}

.text > div > a > button:last-child {
  background-color: transparent;
  color: var(--gold);
  border: 2px solid var(--gold);
  font-weight: 500;
  cursor: pointer;
}

.text p::before {
  content: "";
  display: block;
  width: 290px;
  height: 290px;
  position: absolute;
  background: linear-gradient(119.99deg, #ffbee1 21.9%, #ed70b4 125.17%);

  border-radius: 50%;
  z-index: -1;
  transform: translate(-50%, -50%) rotate(177.74deg);
  animation: breathe 4s cubic-bezier(0.39, 0.575, 0.565, 1) infinite alternate;
}
@keyframes breathe {
  0% {
    filter: blur(394px);
  }
  50% {
    background: linear-gradient(119.99deg, #fadba5 21.9%, #c78054 125.17%);
    filter: blur(200px);
  }
  100% {
    filter: blur(394px);
  }
}
@keyframes breathe-reverse {
  0% {
    background: linear-gradient(119.99deg, #fadba5 21.9%, #c78054 125.17%);
    filter: blur(394px);
  }
  50% {
    filter: blur(200px);
  }
  100% {
    background: linear-gradient(119.99deg, #fadba5 21.9%, #c78054 125.17%);
    filter: blur(394px);
  }
}
@media (max-width: 1080px) {
  .hero {
    width: 100vw;
    margin: auto !important;
    flex-direction: column;
  }
  .text {
    flex: 0;
  }
  .bannner {
    margin-top: 50px;
  }
  .text {
    width: 90vw;
    height: fit-content !important;
  }
  .text > div > a > button {
    outline: none;
    border: none;
    padding: clamp(14px, 1.5vw, 21px) clamp(21px, 3vw, 42px);
    margin-right: 20px;
    border-radius: 50px;
    font-size: clamp(14px, 1.5vw, 20px);
    border: 2px solid transparent;
    transition: all 0.2s ease-in-out;
  }
  .banner img {
    margin-top: 50px;
    width: 100vw;
    max-width: 600px;
  }
  .banner::after {
    display: none;
  }
}
@media (max-width: 520px) {
  .hero {
    height: fit-content !important;
  }
  .banner img {
    max-width: 120vw;
  }
}
@media (max-width: 450px) {
  .banner img {
    max-width: 120vw;
  }
  .banner {
    overflow-x: hidden;
  }
}
