.container {
  display: flex;
  margin: auto;
  width: 90vw;
  max-width: 1200px;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
}
.circle {
  animation: rotate 20s linear infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 50px;
}
.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 74px;
  height: 74px;
  border-radius: 50%;
  background-color: var(--gold);
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow img {
  animation: updown 2s ease-in-out infinite;
}
.numbers {
  color: var(--white);
  padding: 15px 15px;
  display: flex;
  align-items: center;
  background: rgba(256, 256, 256, 0.1);
  border-radius: 8px;
  margin-right: 20px;
}
.numbers h3 {
  font-weight: 700;
  font-size: clamp(24px, 3.5vw, 48px);
  line-height: 100%;
  margin-bottom: clamp(14px, 1.5vw, 21px);
}
.numbers p {
  margin-left: 12px;
  font-weight: 500;
  font-size: clamp(14px, 1.5vw, 21px);
  line-height: 100%;
}

@keyframes updown {
  0% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(-5px);
  }
}
@media (max-width: 768px) {
  .container {
    display: grid;
    margin-top: 80px;
    grid-template-areas:
      "header header header"
      "number1 number1 number1"
      "number2 number2 number2"
      "number3 number3 number3";
    gap: 20px;
  }
  .group {
    margin-right: 0;
    overflow: hidden;
  }
  .rotating {
    grid-area: header;
  }
  .numbers {
    padding: 25px 12px;
  }

  .number1 {
    grid-area: number1;
  }
  .number2 {
    grid-area: number2;
  }
  .number3 {
    grid-area: number3;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1080px) {
  .container {
    margin-top: 90px;
  }
}
